import React from 'react';
import { useTranslation } from 'react-i18next';

function Mission()
{
    const {t} = useTranslation();

    return(
        <div id="mission-container" class="container-fluid">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col">
                        <h2> {t("mission")} </h2>
                        <p style={{textAlign: "left"}}>
                            {t("mission-statement")}
                        </p>
                    </div>
                    <div class="col">
                        <video width="100%" height="100%" autoPlay muted loop><source src={require('../videos/singing.mov')} type="video/mp4"/></video>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mission;