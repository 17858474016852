import React, {useState} from 'react'
import Lottie from 'react-lottie';
import instagramData from '../images/icons/instagram.json'
import facebookData from '../images/icons/facebook.json'
import { useTranslation } from 'react-i18next';

function Socials()
{
    const {t} = useTranslation();

    const instagramDefaultOptions = {
        loop: false,
        autoplay: false,
        animationData: instagramData,
        renderer: 'svg'
    };
    const facebookDefaultOptions = {
        loop: false,
        autoplay: false,
        animationData: facebookData,
        renderer: 'svg'
    };

    const [instagramStopped, setInstagramStopped] = useState(true);
    const [facebookStopped, setFacebookStopped] = useState(true);

    return(
        <div id="socials-container" class="container-fluid">
            <div class="container">
                        <h2 style={{textAlign: "center"}}> {t("keepintouch")}: </h2>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder={t("keepintouch-email-placeholder")} aria-label="Email" aria-describedby="button-addon2"/>
                            <button class="btn btn-outline-secondary" type="button" id="button-addon2">{t("keepintouch-follow")}</button>
                        </div>
                        <div class="social-icon-container">
                            <div class="social-icon icon-filter-brown" onMouseEnter={()=>{setInstagramStopped(false)}} onMouseLeave={()=>{setInstagramStopped(true)}}>
                                <Lottie
                                    options={instagramDefaultOptions}
                                    height={35}
                                    width={35}
                                    isClickToPauseDisabled
                                    isStopped={instagramStopped}
                                />
                            </div>
                            <div class="social-icon icon-filter-brown" onMouseEnter={()=>{setFacebookStopped(false)}} onMouseLeave={()=>{setFacebookStopped(true)}}>
                                <Lottie
                                    options={facebookDefaultOptions}
                                    height={35}
                                    width={35}
                                    isClickToPauseDisabled
                                    isStopped={facebookStopped}
                                />
                            </div>
                </div>
            </div>
        </div>
    );
}

export default Socials;