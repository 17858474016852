import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import Navbar from './Navbar'

function Header(props)
{
    const {t} = useTranslation();
    const [isRusTagVisible, setIsRusVisible] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    const handleSwitchClick = () => {
        setIsRusVisible(!isRusTagVisible);
        if(!isRusTagVisible) i18n.changeLanguage("ru");
        else i18n.changeLanguage("en");
    }


    
    return(
        <div id="main">
            <Navbar/>
            <div id="" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner darken-bg-green">
                    <div class="carousel-item active">
                        <img src={require('../images/BG_3.jpg')} class="d-block w-100" alt="..."/>
                    </div>
                    <div class="carousel-caption d-md-block"
                            onMouseEnter={()=> setIsHovered(true)}
                            onMouseLeave={()=> setIsHovered(false)}
                        >
                            <div class="animated-header">
                                <div className="animated-text">
                                    <h5 class="animated-line">{t("title")}</h5>
                                    <h5 class="animated-line">{t("title-code")}</h5>
                                </div>
                            </div>
                            <p class="caption-subtitle">{t("subtitle")}</p>
                            <label class="switch">
                                    <input type="checkbox" onClick={handleSwitchClick} />
                                    <span class="slider round">
                                        <div class="slider-div">
                                            <p class={isRusTagVisible ? 'hidden' : 'toggle-label'}>ENG</p>
                                            <p class={isRusTagVisible ? 'toggle-label switch-text-right' : 'hidden'}>RUS</p>
                                        </div>
                                    </span>
                            </label>
                        
                        </div>
                </div>
            </div>
        </div>
    );
}

export default Header;