import React, {useEffect, useState} from 'react'

const API_KEY = "b60d791a0fda9d97f2a95f2841ab85b6"
    const BIBLE_ENG_ID = "de4e12af7f28f599-02";
    
    const VERSES = [
        `JER.29.11`,
        `PSA.23`,
        `1COR.4.4-8`,
        `PHP.4.13`,
        `JHN.3.16`,
        `ROM.8.28`,
        `ISA.41.10`,
        `PSA.46.1`,
        `GAL.5.22-23`,
        `HEB.11.1`,
        `2TI.1.7`,
        `1COR.10.13`,
        `PRO.22.6`,
        `ISA.40.31`,
        `JOS.1.9`,
        `HEB.12.2`,
        `MAT.11.28`,
        `ROM.10.9-10`,
        `PHP.2.3-4`,
        `MAT.5.43-44`,
    ];

function VerseOfTheDay()
{
    const [verseData, setVerseData] = useState({ reference: '', content: '' });

    useEffect(()=> {
        const fetchRandomVerse = async () => {
            const verseIndex = Math.floor(Math.random() * VERSES.length);
            const verseID = VERSES[verseIndex];

            try
            {
                const response = await fetch(
                    `https://api.scripture.api.bible/v1/bibles/${BIBLE_ENG_ID}/search?query=${verseID}`,
                    {
                        headers:{
                            'api-key': API_KEY,
                        },
                    }
                );

                if(!response.ok)
                {
                    throw new Error(`HTTP ERROR! Status: ${response.status}`);
                }

                const {data, meta } = await response.json();

                const passage = data.passages[0];
                setVerseData({
                    reference: passage.reference,
                    content: extractPlainText(passage.content),
                });
                console.log(verseData.content); 

            }catch(error)
            {
                console.error("Error fetching random Bible Verse: ", error);
            }
        };

        fetchRandomVerse();
    }, []);

    const extractPlainText = (htmlContent) => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;
        return tempElement.textContent || tempElement.innerText;
    };

    return(
        <div id="VOTD">
            <p>{verseData.content}</p>
        </div>
    );
}

export default VerseOfTheDay;