import React from 'react';
import VerseOfTheDay from './VerseOfTheDay';

function Navbar()
{
    return(
        <nav class="navbar bg-dark bg-body-tertiary">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img src={require('../images/logo.png')} alt="Bootstrap" width="30" height="24"/>
                </a>
                <VerseOfTheDay/>
            </div>
        </nav>
    );
}

export default Navbar;