import './App.css';
import Header from './components/Header';
import Cards from './components/Cards';
import Mission from './components/Mission';
import Socials from './components/Socials';

function App() {

    return (
    <div className="App">
        <Header/>
        <Cards/>
        <Mission/>
        <Socials/>
    </div>
    );
}

export default App;
