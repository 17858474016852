import React from 'react';
import { useTranslation } from 'react-i18next';

function Cards()
{
    const {t} = useTranslation();
    return(
        <div class="cards-container">
            <div id="cards">
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col">
                        <div class="card">
                            <div style={{backgroundColor: "#565550"}}><h5 class="card-title card-header">{t("services")}</h5></div>
                            <div class="card-body card-image darken-bg-brown">
                                <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">

                                    <div class="carousel-inner" data-bs-interval="10000">
                                        <div class="carousel-item active">
                                        <img src={require('../images/pastor.jpg')} class="d-block w-100" alt="..."/>
                                        </div>
                                        <div class="carousel-item" data-bs-interval="10000">
                                        <img src={require('../images/sundayschool_singing.jpg')} class="d-block w-100" alt="..."/>
                                        </div>
                                        <div class="carousel-item" data-bs-interval="10000">
                                        <img src={require('../images/singing.jpg')} class="d-block w-100" alt="..."/>
                                        </div>
                                    </div>
                                    <button class="carousel-control-prev" aria-label="previous" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    </button>
                                    <button class="carousel-control-next" aria-label="next" type="button" data-bs-target="#carouselExampleSlidesOnly" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card">
                            <div style={{backgroundColor: "#565550"}}><h5 class="card-title card-header">{t("schedule")}</h5></div>
                            <div class="card-body">
                                <p class="card-text card-text-header">{t("dayofweek-sunday")}:</p>
                                <div class="card-subbody">
                                    <p class="card-text">{t("schedule-service")}: 11am</p>
                                    <p class="card-text">{t("schedule-prayer")}: 6pm</p>
                                </div>
                                <p class="card-text card-text-header">{t("dayofweek-thursday")}:</p>
                                <div class="card-subbody">
                                    <p class="card-text">{t("schedule-practice")}</p>
                                </div>
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card">
                            <div style={{backgroundColor: "#565550"}}><h5 class="card-title card-header">{t("prayers")}</h5></div>
                            <div class="card-body card-image darken-bg-brown">
                                <img src={require('../images/praying.png')} class="" alt="..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cards;